<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

// import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import loadingContact from "@/components/widgets/loading_contact.json"
import Swal from "sweetalert2";
import axios from "axios";
import Lottie from "@/components/widgets/lottie.vue";
import { inject } from "vue";

export default {
  page: {
    title: "Contacts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    let debounceTimeout;
    const debounce = (func, timeout = 300) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(func, timeout);
    }

    return { Toast, debounce };
  },
  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    Multiselect,
  },
  data() {
    return {
      title: "Contacts",
      items: [
        {
          text: "Xmart",
          href: "/",
        },
        {
          text: "Contacts",
          active: true,
        },
      ],
      timeConfig: {
        enableTime: false,
        dateFormat: "d M, Y",
      },
      date2: null,
      defaultOptions: {
        animationData: loadingContact,
      },
      defaultOptions1: {
        animationData: animationData1,
      },
      searchQuery: null,
      page: 1,
      perPage: 10,
      pages: [],
      formAddCustomer: {
        name: "",
        company: "",
        phone: "",
        note: null,
        tags: [],
      },
      value: null,
      tagvalue: null,
      tagoption: [],
      contactsList: [],
      isEdit: false,
      phoneRedirect: 0,
      pagination: [],
      secureParams: "&ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt"),
      loadingFetch: false,
      db: inject("db")
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.contactsList);
    },
    resultQuery() {
      if(this.searchQuery){
        this.debounce(async () => {
          var baseUrl = process.env.VUE_APP_URL_API;
          let params = "&ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
          let url = baseUrl+"contact/getCustomer?Start=0&Count=10"+params+"&Keyword="+this.searchQuery
          await this.getContact(url);
        }, 1000)
        return this.contactsList;
      } else {
        return this.contactsList;
      }
    },
  },
  created() {
    // this.setPages();
    // console.log("set pages called");
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    showdetail(data) {
      console.log(data);
      document.getElementById("image").setAttribute("src", data.avatar);
      document.getElementById("name").innerHTML = data.name;
      document.getElementById("phoneno").innerHTML = data.phone;
      document.getElementById("leadscoreno").innerHTML = data.status;
      this.phoneRedirect = data.phone;
      document.getElementById("tags").innerHTML = "";
      if (data.tags !== null) {
        data.tags.forEach((tag) => {
          console.log(tag);
          document.getElementById("tags").innerHTML +=
            '&nbsp;<span class="badge badge-soft-primary">' +
            tag.Title +
            "</span>";
        });
      }
    },
    setPages() {
      let numberOfPages = Math.ceil(this.contactsList.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(contactsList) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return contactsList.slice(from, to);
    },
    async editdata(data) {
      // let extraParam = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
      // let url = process.env.VUE_APP_URL_API+"customer-api/getCustomerByID"+extraParam+"&ID="+data.id

      const customerData = await axios.get(
        process.env.VUE_APP_URL_API + "customer-api/getCustomerByID",
        {
          params: {
            ClientID: process.env.VUE_APP_CLIENT_ID,
            AccessToken: localStorage.getItem("jwt"),
            ID: data.id,
          },
        }
      );

      let dataCustomer = null;
      if (customerData.status === 200) {
        dataCustomer = customerData.data.Data;
      }

      console.log(dataCustomer);

      this.isEdit = true;
      this.tagvalue = [];

      // document.getElementById("note").value = dataCustomer.Note;
      this.formAddCustomer.note = dataCustomer.Note;

      document.getElementById("companyname").value = JSON.parse(
        localStorage.getItem("user")
      ).Company.Name;
      this.formAddCustomer.company =
        document.getElementById("companyname").value;

      document.getElementById("exampleModalLabel").innerHTML = "Edit Contact";
      let result = this.contactsList.findIndex((o) => o.id == data.id);

      document.getElementById("id").value = this.contactsList[result].id;
      document.getElementById("customername").value =
        this.contactsList[result].name;
      this.formAddCustomer.name = document.getElementById("customername").value;
      document
        .getElementById("customername")
        .setAttribute("readonly", "readonly");

      document.getElementById("phone").value = this.contactsList[result].phone;
      document.getElementById("phone").setAttribute("readonly", "readonly");
      this.formAddCustomer.phone = document.getElementById("phone").value;

      if (this.contactsList[result].tags !== null) {
        this.contactsList[result].tags.forEach((item) => {
          this.tagvalue.push(item.ID);
        });
        this.formAddCustomer.tags = this.tagvalue;
      }
      document.getElementById("edit-btn").style.display = "block";
      document.getElementById("add-btn").style.display = "none";
    },
    async updateorder() {
      let tagSave = [];
      if (this.tagvalue) {
        this.tagvalue.map((item) => {
          var checkTagOption = this.tagoption.findIndex(
            (idx) => idx.value === item
          );
          if (checkTagOption !== -1)
            tagSave.push({
              ID: item,
              Title: this.tagoption[checkTagOption].label,
            });
          else
            tagSave.push({
              ID: 0,
              Title: item,
            });
        });
      }

      const dataEdit = new FormData();
      dataEdit.append("ID", document.getElementById("id").value);
      dataEdit.append("Note", this.formAddCustomer.note);
      dataEdit.append("Tags", JSON.stringify(tagSave));

      document.getElementById("edtclosemodal").click();

      var ClientID = process.env.VUE_APP_CLIENT_ID;
      var AccessToken = localStorage.getItem("jwt");

      const saveEdited = await axios
        .post(
          process.env.VUE_APP_URL_API +
            "customer-api/editCustomer?ClientID=" +
            ClientID +
            "&AccessToken=" +
            AccessToken,
          dataEdit
        )
        .then((response) => {
          return response;
        })
        .catch((er) => {
          return er;
        });

      var responseStatusCode = saveEdited.data.Meta.Code;
      var responseStatusMsg = saveEdited.data.Meta.Message;

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      if (responseStatusCode != 200) {
        Toast.fire({
          icon: "error",
          title: responseStatusMsg,
        });
      } else {
        var idxList = this.contactsList.findIndex(
          (el) => el.id === parseInt(document.getElementById("id").value)
        );
        this.contactsList[idxList].tags = tagSave;
        Toast.fire({
          icon: "success",
          title: responseStatusMsg,
        });
      }
    },
    async addorder() {
      this.isEdit = false;
      var contactname = document.getElementById("customername").value;
      var companyname = document.getElementById("companyname").value;
      var phone = document.getElementById("phone").value;
      if (contactname != "" && companyname != "" && phone != "") {
        var companyCode = JSON.parse(localStorage.getItem("user")).Company
          .CompanyCode;
        var data = {
          kodemb: companyCode,
          phone: this.formAddCustomer.phone,
          photo: null,
          nama: this.formAddCustomer.name,
          message: {
            id: {
              fromMe: false,
              remote: "6285655401980@c.us",
              id: "3AF05FD177F13322BAE7",
              _serialized: "false_6285655401980@c.us_3AF05FD177F13322BAE7",
            },
            type: "chat",
            description: "",
            content: "sv",
            from: "6285655401980@c.us",
            ack: 1,
            timestamp: 1673601816,
          },
        };

        const userSaved = await axios.post(
          process.env.VUE_APP_BASE_URL + "api-helper/storeUserChat",
          JSON.stringify(data)
        );
        if (userSaved.data.Code == 200) {
          this.contactsList.push(data);
        }
      }
      document.getElementById("edtclosemodal").click();
      document.getElementById("addform").reset();
    },
    deletedata(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          const listDeleted =
            this.contactsList[this.contactsList.indexOf(event)];
          var param =
            "?ClientID=" +
            process.env.VUE_APP_CLIENT_ID +
            "&AccessToken=" +
            localStorage.getItem("jwt") +
            "&CustomerID=" +
            listDeleted.id;

          axios
            .get(
              process.env.VUE_APP_URL_API +
                "customer-api/deleteCustomer" +
                param
            )
            .then((res) => {
              if (res.data.Meta.Code === 200) {
                // proccess deleted index db
                this.db.transaction(["customer"], "readwrite")
                .objectStore("customer")
                .delete(listDeleted.id);

                this.contactsList.splice(this.contactsList.indexOf(event), 1);
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    addnew() {
      this.isEdit = false;
      document.getElementById("addform").reset();
      document.getElementById("companyname").value = JSON.parse(
        localStorage.getItem("user")
      ).Company.Name;
      this.formAddCustomer.company =
        document.getElementById("companyname").value;
      document.getElementById("exampleModalLabel").innerHTML = "Add Contact";
      document.getElementById("add-btn").style.display = "block";
      document.getElementById("edit-btn").style.display = "none";
    },
    deleteMultiple() {
      let ids_array = [];
      var items = document.getElementsByName("chk_child");
      items.forEach(function (ele) {
        if (ele.checked == true) {
          var trNode = ele.parentNode.parentNode.parentNode;
          var id = trNode.querySelector(".id a").innerHTML;
          ids_array.push(id);
        }
      });
      if (typeof ids_array !== "undefined" && ids_array.length > 0) {
        if (confirm("Are you sure you want to delete this?")) {
          var cusList = this.contactsList;
          ids_array.forEach(function (id) {
            cusList = cusList.filter(function (customer) {
              return customer.id != id;
            });
          });
          this.contactsList = cusList;
          document.getElementById("checkAll").checked = false;
          var itemss = document.getElementsByName("chk_child");
          itemss.forEach(function (ele) {
            if (ele.checked == true) {
              ele.checked = false;
              ele.closest("tr").classList.remove("table-active");
            }
          });
        } else {
          return false;
        }
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    async fetchAllTagsDB() {
      const tagsDB = await axios.get(
        process.env.VUE_APP_URL_API +
          "customer-api/getAllTags?ClientID=" +
          process.env.VUE_APP_CLIENT_ID +
          "&AccessToken=" +
          localStorage.getItem("jwt")
      );
      tagsDB.data.Data.forEach((item) => {
        this.tagoption.push({
          value: item.ID,
          label: item.Title,
        });
      });
    },
    redirectToChat(phone) {
      this.$router.push({
        path: "/chat/" + phone,
      });
    },
    async getContact(url) {
      this.contactsList = [];
      let keywordSplit = url.split("&");
      let keyword = keywordSplit[keywordSplit.length - 1];
      let checkKeyword = keyword.split("=")
      if(checkKeyword[0] !== 'Keyword' && this.searchQuery !== null){
        url = url+"&Keyword="+this.searchQuery;
      }
      this.loadingFetch = true;
      await axios
        .get(url)
        .then(async (data) => {
          const res = data.data;
          if (res.Meta.Code == 200) {
            this.pagination = res.Data.paginationInfo
            for (let index = 0; index < res.Data.list.length; index++) {
              const item = res.Data.list[index];
              var profile = "";
              profile = item.Avatar.Original;

              this.contactsList.push({
                id: item.ID,
                contactId: "#VZ00" + index,
                name: item.Name,
                tags: item.Tags,
                phone: parseInt(item.Phone),
                agentCount: item.AgentAssigned ? item.AgentAssigned.length : 0,
                avatar: profile,
                status: item.Status,
                agentHandle: item.AgentAssigned ?? [],
                createdAt: item.Created
              });
            }
            this.setPages();
            document.querySelector("#edtclosemodalImport").click();
            this.loadingFetch = false;
          }
        })
        .catch((er) => {
          console.log(er);
        });
    },
    async importContact() {
      var files = document.querySelector("#fileImport").files[0];
      var kodeMB = JSON.parse(localStorage.getItem("user")).Company.CompanyCode;

      let formData = new FormData();
      formData.append("File", files);
      formData.append("KodeMB", kodeMB);

      let secureParam =
        "?ClientID=" +
        process.env.VUE_APP_CLIENT_ID +
        "&AccessToken=" +
        localStorage.getItem("jwt");
      let url = process.env.VUE_APP_URL_API + "contact/import" + secureParam;

      const importReq = await axios.post(url, formData);
      const response = importReq.data;
      if (response.Meta.Code !== 200) {
        this.Toast.fire({
          icon: "error",
          title: response.Meta.Message,
        });
      }

      this.Toast.fire({
        icon: "success",
        title: response.Meta.Message,
      });
      await this.getContact();
    },
    async exportCustomer()
    {
      let url = process.env.VUE_APP_BASE_URL+"api/contact/export";
      let secureParam = "?ClientID="+process.env.VUE_APP_CLIENT_ID
      +"&AccessToken="+localStorage.getItem("jwt")
      +"&KodeMB="+JSON.parse(localStorage.getItem("user")).Company.CompanyCode

      let linkExport = url+secureParam;
      this.Toast.fire({
        icon: "info",
        title: "Processing export data please wait...",
        timer: 10000
      })
      document.querySelector("#btnExport").setAttribute("disabled","disabled");
      let exportReq = await axios.get(linkExport);
      document.querySelector("#btnExport").removeAttribute("disabled");
      if(exportReq.status !== 200){
        return this.Toast.fire({
          icon: 'error',
          title: exportReq.statusText
        })
      }

      let data = exportReq.data

      if(data.Meta.Code !== 200){
        return this.Toast.fire({
          icon: 'error',
          title: data.Meta.Message
        })
      } else {
        let urlExcel = data.Data;
        let anchorCreate = document.createElement("a");
        anchorCreate.setAttribute("href", urlExcel);
        anchorCreate.click();
      }
    }
  },
  async beforeMount() {
    var baseUrl = process.env.VUE_APP_URL_API;
    let params = "&ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
    let url = baseUrl+"contact/getCustomer?Start=0&Count=10"+params
    await this.getContact(url);
    this.fetchAllTagsDB();
  },
  mounted() {
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center flex-wrap gap-2">
              <div class="flex-grow-1">
                <button
                  class="btn btn-info add-btn me-1"
                  style="display: none"
                  data-bs-toggle="modal"
                  href="#showmodal"
                  @click="addnew"
                >
                  <i class="ri-add-fill me-1 align-bottom"></i> Add Contacts
                </button>
                <a href="/TemplateImportContact.xlsx" class="btn btn-primary me-1">
                  <i class="ri-download-2-line me-1 align-bottom"></i>
                  Download template
                </a>
                <button
                  class="btn btn-info add-btn me-1"
                  style=""
                  data-bs-toggle="modal"
                  href="#modalImport"
                >
                  <i class="ri-file-excel-2-line me-1 align-bottom"></i> Import
                  Contacts
                </button>
                <button
                  class="btn btn-success add-btn me-1"
                  style=""
                  id="btnExport"
                  @click="exportCustomer"
                >
                  <i class="ri-file-excel-line me-1 align-bottom"></i> Export
                  Contacts
                </button>
                <button class="btn btn-soft-danger" @click="deleteMultiple">
                  <i class="ri-delete-bin-2-line"></i>
                </button>
              </div>
              <!--
              <div class="flex-shrink-0">
                <div class="hstack text-nowrap gap-2">
                  <button class="btn btn-danger" data-bs-toggle="modal">
                    <i class="ri-filter-2-line me-1 align-bottom"></i> Filters
                  </button>
                  <button class="btn btn-soft-success shadow-none">Import</button>
                  <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false"
                    class="btn btn-soft-info shadow-none">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                    <li><a class="dropdown-item" href="#">All</a></li>
                    <li><a class="dropdown-item" href="#">Last Week</a></li>
                    <li><a class="dropdown-item" href="#">Last Month</a></li>
                    <li><a class="dropdown-item" href="#">Last Year</a></li>
                  </ul>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card" id="contactList">
          <div class="card-header">
            <div class="row g-3">
              <div class="col-md-4">
                <div class="search-box">
                  <input
                    v-model="searchQuery"
                    type="text"
                    class="form-control search"
                    ref="querySearch"
                    placeholder="Search for contact..."
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <!-- <div class="col-md-auto ms-auto">
                <div class="d-flex align-items-center gap-2">
                  <span class="text-muted flex-shrink-0">Sort by: </span>
                  <Multiselect class="form-control" style="width:150px;" v-model="value" :close-on-select="true"
                    :searchable="true" :create-option="true" :options="[
                            { value: 'Name', label: 'Name' },
                            { value: 'Company', label: 'Company' },
                            { value: 'Lead', label: 'Lead' }
                          ]" />
                </div>
              </div> -->
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card mb-3">
                <table
                  class="table align-middle table-nowrap mb-0"
                  id="customerTable"
                >
                  <thead class="table-light">
                    <tr>
                      <th scope="col" style="width: 50px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                          />
                        </div>
                      </th>
                      <th class="sort" data-sort="name" scope="col">Name</th>
                      <th class="sort" data-sort="phone" scope="col">
                        Phone No
                      </th>
                      <th class="sort" data-sort="Designation" scope="col">
                        Tags
                      </th>
                      <th class="sort" data-sort="Designation" scope="col">
                        Agent
                      </th>
                      <th class="sort" data-sort="Designation" scope="col">
                        CreatedAt
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all" v-if="resultQuery.length">
                    <tr v-for="(data, index) of resultQuery" :key="index">
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            value="option1"
                          />
                        </div>
                      </th>
                      <td class="id" style="display: none">
                        <a
                          href="javascript:void(0);"
                          class="fw-medium link-primary"
                          >{{ data.id }}</a
                        >
                      </td>
                      <td class="">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0">
                            <img
                              :src="data.avatar"
                              alt=""
                              class="user_image avatar-xs rounded-circle"
                            />
                          </div>
                          <div class="flex-grow-1 ms-2 name">
                            {{ data.name }}
                          </div>
                        </div>
                      </td>
                      <td class="phone">{{ data.phone }}</td>
                      <td class="tags">
                        <span v-for="(tag, index) of data.tags" :key="index">
                          <span class="badge badge-soft-primary">{{
                            tag.Title
                          }}</span
                          >&nbsp;
                          <br v-if="index == 2" />
                        </span>
                      </td>
                      <td class="agent">
                        <ol class="list-group list-group-numbered">
                          <li v-for="agent of data.agentHandle" :key="agent.ID" class="list-group-item">{{ agent.Name }}</li>
                        </ol>
                      </td>
                      <td class="Created">
                        {{ data.createdAt }}
                      </td>
                      <td>
                        <ul class="list-inline hstack gap-2 mb-0">
                          <li
                            class="list-inline-item edit"
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Message"
                          >
                            <a
                              href-link=""
                              v-on:click="redirectToChat(data.phone)"
                              class="text-muted d-inline-block"
                            >
                              <i class="ri-question-answer-line fs-16"></i>
                            </a>
                          </li>
                          <li class="list-inline-item">
                            <div class="dropdown">
                              <button
                                class="btn btn-soft-secondary btn-sm dropdown"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i class="ri-more-fill align-middle"></i>
                              </button>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <a
                                    class="dropdown-item edit-item-btn"
                                    data-bs-toggle="modal"
                                    href="#showmodal"
                                    @click="editdata(data)"
                                  >
                                    <i
                                      class="ri-pencil-fill align-bottom me-2 text-muted"
                                    ></i>
                                    Edit</a
                                  >
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item remove-item-btn"
                                    @click="deletedata(data)"
                                  >
                                    <i
                                      class="ri-delete-bin-fill align-bottom me-2 text-muted"
                                    ></i>
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="list form-check-all" v-if="loadingFetch">
                    <tr>
                      <td colspan="7" class="text-center">
                        <lottie
                          colors="primary:#0ab39c,secondary:#405189"
                          :options="defaultOptions"
                          :width="150"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult" v-if="resultQuery.length === 0 && !loadingFetch">
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Sorry! No Result Found</h5>
                    <p class="text-muted mb-0">
                      We've searched more than 150+ Orders We did not find any
                      orders for you search.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a
                    class="page-item pagination-prev"
                    href="#"
                    v-if="this.pagination.hasPreviousPage"
                    @click="this.getContact(this.pagination.PreviousLink+this.secureParams)"
                  >
                    Previous
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li
                      v-for="(pageNumber, index) in this.pagination.page"
                      :key="index"
                      :class="{
                        active: pageNumber.current,
                        // disabled: pageNumber == '...',
                      }"
                      @click="this.getContact(pageNumber.Link+this.secureParams)"
                    >
                      <a class="page" href="#">{{ pageNumber.Number }}</a>
                    </li>
                  </ul>
                  <a
                    class="page-item pagination-next"
                    href="#"
                    @click="this.getContact(this.pagination.NextLink+this.secureParams)"
                    v-if="this.pagination.hasNextPage"
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->

      <!-- <div class="col-xxl-3">
        <div class="card" id="contact-view-detail">
          <div class="card-body text-center">
            <div class="position-relative d-inline-block">
              <img src="@/assets/images/users/avatar-10.jpg" id="image" alt=""
                class="avatar-lg rounded-circle img-thumbnail">
              <span class="contact-active position-absolute rounded-circle bg-success"><span
                  class="visually-hidden"></span></span>
            </div>
            <h5 class="mt-4 mb-1" id="name">Tonya Noble</h5>
            <p class="text-muted" id="company">Nesta Technologies</p>

            <ul class="list-inline mb-0">
              <li class="list-inline-item avatar-xs">
                <a href="javascript:void(0);" @click="redirectToChat(this.phoneRedirect)" class="avatar-title bg-soft-warning text-warning fs-15 rounded">
                  <i class="ri-question-answer-line"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <h6 class="text-muted text-uppercase fw-semibold mb-3">Personal Information</h6>
            <p class="text-muted mb-4">Hello, I'm Tonya Noble, The most effective objective is
              one that is tailored to the job you are applying for. It states what kind of
              career you are seeking, and what skills and experiences.</p>
            <div class="table-responsive table-card">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <td class="fw-medium" scope="row">Phone No</td>
                    <td id="phoneno">414-453-5725</td>
                  </tr>
                  <tr>
                    <td class="fw-medium" scope="row">Status</td>
                    <td id="leadscoreno">154</td>
                  </tr>
                  <tr>
                    <td class="fw-medium" scope="row">Tags</td>
                    <td id="tags">
                      <span class="badge badge-soft-primary">Lead</span>
                      <span class="badge badge-soft-primary">Partner</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div> -->
    </div>
    <!--end row-->
    <div
      class="modal fade"
      ref="modalsForm"
      id="showmodal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header bg-soft-info p-3">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form action="" id="addform">
            <div class="modal-body">
              <input type="hidden" id="id" />
              <div class="row g-3">
                <div class="col-lg-12">
                  <div>
                    <label for="name-field" class="form-label">Name</label>
                    <input
                      v-model="this.formAddCustomer.name"
                      type="text"
                      id="customername"
                      class="form-control"
                      placeholder="Enter name"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="company_name-field" class="form-label"
                      >Company Name</label
                    >
                    <input
                      v-model="this.formAddCustomer.company"
                      readonly
                      type="text"
                      id="companyname"
                      class="form-control"
                      placeholder="Enter company name"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div>
                    <label for="phone-field" class="form-label">Phone</label>
                    <input
                      v-model="this.formAddCustomer.phone"
                      type="text"
                      id="phone"
                      class="form-control"
                      placeholder="Enter phone no"
                      required
                    />
                  </div>
                </div>
                <div class="col-lg-12" v-if="this.isEdit">
                  <label for="" class="form-label">Tags</label>
                  <Multiselect
                    v-model="this.tagvalue"
                    mode="tags"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="true"
                    :options="tagoption"
                  />
                </div>
                <div class="col-lg-12" v-if="this.isEdit">
                  <div>
                    <label for="exampleFormControlTextarea5" class="form-label"
                      >Note</label
                    >
                    <textarea
                      v-model="this.formAddCustomer.note"
                      class="form-control"
                      id="note"
                      rows="3"
                      style="height: 125px"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="edtclosemodal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="add-btn"
                  @click="addorder"
                >
                  Add Contact
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="edit-btn"
                  @click="updateorder()"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Modal import -->
    <div
      class="modal fade"
      ref="modalsForm"
      id="modalImport"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0">
          <div class="modal-header bg-soft-info p-3">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form action="" id="addform">
            <div class="modal-body">
              <div class="row g-3">
                <div class="col-lg-12">
                  <div>
                    <label for="name-field" class="form-label"
                      >File Import <small>.xlsx</small></label
                    >
                    <input
                      type="file"
                      id="fileImport"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="edtclosemodalImport"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-success"
                  id="add-btn"
                  @click="importContact"
                >
                  Import Contact
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>
